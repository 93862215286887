<template>
    <v-hover v-slot="{ hover }" open-delay="200">
        <v-card height="400" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click.stop="dialog = true">
            <v-img class="white--text align-end" height="200px" src="../assets/images/panel_image/mv_rna-seq.png">
                <v-card-title class="black--text text--darken-1">RNA-seq</v-card-title>
            </v-img>

            <v-card-text class="text--primary">
                <div>真核生物、原核生物の遺伝子発現について、polyA RNA、ribosomal RNA除去、smallRNA解析等をご希望の方はこちらから仕様をご記入ください。</div>
            </v-card-text>

            <v-card-actions>
                <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                    <v-card>
                        <v-toolbar dark color="secondary">
                            <v-btn icon dark @click="dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>仕様登録（RNA-seq）</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <RNAseqSheet></RNAseqSheet>
                    </v-card>
                </v-dialog>
            </v-card-actions>
        </v-card>
    </v-hover>
</template>

<script>
import RNAseqSheet from '../ordersheets/RNAseqSheet.vue';

export default {
    components: {
        RNAseqSheet,
    },

    data() {
        return {
            dialog: false,
        }

    },
};
</script>