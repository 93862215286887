<template>
    <div class="wrapper">
        <v-alert v-model="alert" v-if="hasSentMail === 1" border="left" close-text="Close Alert" color="primary"
            icon="mdi-emoticon" dark dismissible>
            ご入力頂いたメールアドレス宛に内容を送信しました。メールが届かない場合はご記入いただいたメールアドレスが間違っている可能性がありますので、大変お手数ですがもう一度ご入力をお願いします。
        </v-alert>
        <v-alert v-model="alert" v-else-if="hasSentMail === 99" border="right" close-text="Close Alert" color="#C51162"
            icon="mdi-emoticon-dead" dark dismissible>
            メールの送信に失敗しました。メールアドレスをご確認の上、再度送信ボタンを押してください。
        </v-alert>
    </div>
</template>
<style>
.wrapper {
    position: absolute;
    bottom: 0;
    padding-bottom: 0%;
    margin-left: 20%;
    margin-right: 20%;
    z-index: 10
}
</style>
<script>
export default {
    props: {
        'hasSentMail': {
            type: Number,
            default: 0
        },
    }
}
</script>
