<template>
    <v-card>
        <v-app-bar
            color="indigo darken-2"
            dark
        >
            <v-toolbar-title class="text-h6 white--text pl-0">
                {{ getData.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-app-bar>
        <v-container>
            <v-card-text>
                <div>{{ getData.text }}</div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="orange"
                    :href= "getData.links"
                    target="_blank"
                >
                    Learn more
                </v-btn>
            </v-card-actions>            
        </v-container>
    </v-card>    
</template>

<script>
import ToolTipData from '../assets/data/tooltip_data.json';

export default {
    props: ['target'],
    computed: {
        getData() {
            return ToolTipData[this.target]
        },
        // getLinks() {
        //     return require( '${ getData.links }' )
        // }
    },
    data() {
        return {
            object_target: this.target,
        };
    }
}
</script>