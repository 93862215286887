<template>
    <v-hover v-slot="{ hover }" open-delay="200">
        <v-card height="400" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click.stop="dialog = true">
            <v-img class="white--text align-end" height="200px" src="../assets/images/panel_image/mv_chipseq.png">
                <v-card-title class="black--text text--darken-1">ChIP-seq</v-card-title>
            </v-img>

            <v-card-text class="text--primary">
                <div>免疫沈降済みのDNAを用いたライブラリー調製およびシーケンス、比較解析や結合モチーフ解析をご希望の方はこちらから仕様をご記入ください。</div>
            </v-card-text>

            <v-card-actions>
                <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                    <v-card>
                        <v-row align="center" justify= "end">
                            <v-toolbar dark color="secondary">
                                <v-btn icon dark @click="dialog = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>仕様登録（ChIP-seq）</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                        </v-row>
                        <ChIPSheet></ChIPSheet>
                    </v-card>
                </v-dialog>
            </v-card-actions>
        </v-card>
    </v-hover>
</template>

<script>
import ChIPSheet from '../ordersheets/ChIPSheet.vue';

export default {
    components: {
        ChIPSheet
    },

    data() {
        return {
            dialog: false,
        }

    },
};
</script>