<template>
  <v-card-text>
    <SendResults :hasSentMail="this.hasSentMail"></SendResults>
    <v-container>
      <v-form ref="order_form">
        <br>
        <v-row>
          <v-container class="px-0" fluid>
            <v-switch v-model="fromDealer" label="ご入力者が代理店担当者様の場合、オンにしてください"></v-switch>
          </v-container>
        </v-row>
        <v-row v-show="fromDealer" class="text-red">
          <v-col cols="12" sm="6" md="6">
            ▽新規仕様の場合<br>
            最下部の「特記事項」欄にエンドユーザー様のご所属とお名前のご記入を御願い申し上げます。<br><br>
            ▽リピート仕様の場合<br>
            「リピート仕様の詳細」欄にエンドユーザー様のご所属とお名前のご記入を御願い申し上げます。
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="姓*" v-model="form.lastName" :rules="[required, limit_length_32]">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="名*" v-model="form.firstName" :rules="[required, limit_length_32]">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="所属*" v-model="form.belong" :rules="[required, limit_length_64]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="E-mail*" v-model="form.mail" :rules="[required]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-select :items="repeattype" label="新規仕様／リピート仕様" v-model="form.repeatType"></v-select>
          </v-col>
        </v-row>
        <v-row v-show="this.form.repeatType === 'リピート仕様（2回目以降ご注文のお客様向け）'">
          <v-col cols="12" sm="6" md="6">
            <v-textarea class="new-line" label="リピート仕様の詳細" rows="5" v-model="form.repeater" placeholder="（例1）RH99999999と同じ仕様で見積希望です。
              生物種はMouseに変更し、サンプル数は12サンプルです。
（例2）直近の解析と同じ仕様で見積希望です。
              サンプル数は9サンプルです。" hint="RHから始まる8ケタの番号はメールのタイトル、納品データのフォルダ名、作業完了報告書に記載されています。不明な場合は未記入でも問題ありません。"
              persistent-placeholder persistent-hint></v-textarea>
            <br>
            <div class="text-red">※「リピート仕様の詳細」を記載いただいた場合、以降のフォーム入力は不要です</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="8" md="8">
            <v-select :items="applications" label="アプリケーション" v-model="form.application"></v-select>
          </v-col>
          <!-- ツールチップ -->
          <v-dialog v-model="dialog" width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" dark v-bind="attrs" v-on="on">
                mdi-message-question-outline
              </v-icon>
            </template>
            <Tooltips class="new-line" target="wgbs_app"></Tooltips>
          </v-dialog>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3">
            <v-select :items="category" label="生物の分類*" v-model="form.category" :rules="[required]">
            </v-select>
          </v-col>
          <v-col cols="12" sm="8" md="8">
            <v-text-field label="生物種（学名）*" v-model="form.scientificName" :rules="[required]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="サンプル数" v-model="form.sampleNum">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="1サンプルあたりのご希望データ量" v-model="form.sampleDataAmount"
              placeholder="ex.3Gb/8000万トータルリード/2000万リードペア" hint="Gbまたはリード数でご記載ください。アプリケーション名にデータ量が記載されている場合はご記入不要です。"
              persistent-hint>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-select class="new-line" :items="sampletype" label="サンプル種別" v-model="form.sampleType" hint="細胞・組織を選択した場合、核酸抽出作業料金が追加となります。
サンプルによってはお受けできない場合がございますので、その際にはご連絡いたします。" persistent-hint>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-show="
            this.form.sampleType != '' && this.form.sampleType != 'genome DNA'
          " cols="12" sm="8" md="8">
            <v-text-field label="組織/器官" v-model="form.sampleTypeDetail">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="8" md="8">
            <v-select :items="worktype" label="作業種別" v-model="form.workType"></v-select>
          </v-col>
          <!-- ツールチップ -->
          <v-dialog v-model="dialog" width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" dark v-bind="attrs" v-on="on">
                mdi-message-question-outline
              </v-icon>
            </template>
            <Tooltips class="new-line" target="service_type"></Tooltips>
          </v-dialog>
        </v-row>
        <v-row v-show="this.form.workType === 'データ取得＋データ解析' || this.form.workType === 'データ解析のみ'">
          <v-col cols="12" sm="6" md="4">
            <v-select :items="analysistype" label="データ解析内容" v-model="form.analysisType"></v-select>
          </v-col>
          <!-- ツールチップ -->
          <v-dialog v-model="dialog" width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" dark v-bind="attrs" v-on="on">
                mdi-message-question-outline
              </v-icon>
            </template>
            <Tooltips target="scientific_name"></Tooltips>
          </v-dialog>
        </v-row>
        <v-row v-show="
          (this.form.workType === 'データ取得＋ベーシック解析＋スタンダード解析')
        ">
          <v-col cols="12" sm="3" md="3">
            <div class="text-md-body-1">ご希望の解析項目</div>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <!-- ツールチップ -->
            <v-dialog v-model="dialog" width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-message-question-outline
                </v-icon>
              </template>
              <Tooltips class="new-line" target="wgbs_analysis"></Tooltips>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row v-show="
          (this.form.workType === 'データ取得＋ベーシック解析＋スタンダード解析')
        ">
          <v-col>
            <v-checkbox v-model="form.standardPlanType" v-for="(item, index) in standardplantype" color="primary"
              :key="index" :label="item" :value="item"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-show="(this.form.workType === 'データ取得＋ベーシック解析＋スタンダード解析')">
          <v-col cols="12" sm="6" md="4">
            <v-textarea class="new-line" label="比較パターン数" v-model="form.comparingPatturnNum" rows="3" placeholder="(例)
DMC(可変メチル化シトシン)・DMR(可変メチル化領域)の計算および近傍遺伝子:1パターン
DMC・DMR近傍遺伝子のGO解析:1パターン" hint="注文単位が「2群1比較」の解析項目については、解析する比較パターン数をご記載ください。
仮に、n=3, 2群(A群,B群), 計6検体の場合、比較の組合せはA群vsB群のみとなり、1パターンが最大です。" persistent-placeholder persistent-hint>
            </v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="販売代理店のご指定" v-model="form.partnerInfo"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="サンプルのご発送日目安" v-model="form.candidateDateForSampling">
              <template v-slot:append-outer>
                <date-picker v-model="form.candidateDateForSampling" />
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row cols="12" sm="12" md="12">
          <v-textarea class="mx-2" v-model="form.notes" label="その他、特記事項" rows="5"></v-textarea>
        </v-row>
        <v-row cols="12" sm="12" md="12">
          <PrivacyPolicy />
        </v-row>
        <v-row>
          <v-switch v-model="order_rule" insent label="プライバシーポリシーに同意します"></v-switch>
        </v-row>
      </v-form>
    </v-container>
    <v-container>
      <v-bottom-sheet v-model="confirmed_inputdata" persistent>
        <template v-slot:activator="{ on, attrs }">
          <v-row justify="center">
            <v-btn :disabled="!hasRequiredInfo" v-bind="attrs" v-on="on" class="ma-2" block color="primary"
              elevation="3" x-large>
              <v-icon dark left> mdi-file-eye </v-icon>
              申し込み内容を確認する
            </v-btn>
          </v-row>
        </template>
        <v-sheet height=auto>
          <v-container>
            <div class="py-3" text-center>入力内容の確認</div>
            <v-simple-table fixed-header dense height="400px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>項目</th>
                    <th>入力内容</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>お名前</td>
                    <td>{{ form.lastName }} {{ form.firstName }}</td>
                  </tr>
                  <tr>
                    <td>ご所属</td>
                    <td>{{ form.belong }}</td>
                  </tr>
                  <tr>
                    <td>E-mail</td>
                    <td>{{ form.mail }}</td>
                  </tr>
                  <tr>
                    <td>アプリケーション</td>
                    <td>{{ form.application }}</td>
                  </tr>
                  <tr>
                    <td>生物の分類</td>
                    <td>{{ form.category }}</td>
                  </tr>
                  <tr>
                    <td>生物種（学名）</td>
                    <td>{{ form.scientificName }}</td>
                  </tr>
                  <tr>
                    <td>組織・器官</td>
                    <td>{{ form.sampleTypeDetail }}</td>
                  </tr>
                  <tr>
                    <td>サンプル数</td>
                    <td>{{ form.sampleNum }}</td>
                  </tr>
                  <tr>
                    <td>1サンプルあたりのご希望データ量</td>
                    <td>{{ form.sampleDataAmount }}</td>
                  </tr>
                  <tr>
                    <td>サンプル種別</td>
                    <td>{{ form.sampleType }}</td>
                  </tr>
                  <tr>
                    <td>作業種別</td>
                    <td>{{ form.workType }}</td>
                  </tr>
                  <tr>
                    <td>データ解析内容</td>
                    <td>{{ form.analysisType }}</td>
                  </tr>
                  <tr>
                    <td>解析項目</td>
                    <td>{{ form.standardPlanType }}</td>
                  </tr>
                  <tr>
                    <td>比較パターン数</td>
                    <td>{{ form.comparingPatturnNum }}</td>
                  </tr>
                  <tr>
                    <td>その他、特記事項</td>
                    <td>{{ form.notes }}</td>
                  </tr>
                  <tr>
                    <td>販売代理店のご指定</td>
                    <td>{{ form.partnerInfo }}</td>
                  </tr>
                  <tr>
                    <td>サンプルのご発送日目安</td>
                    <td>{{ form.candidateDateForSampling }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
          <v-divider></v-divider>
          <v-container>
            <v-row justify="space-around">
              <v-btn class="mt-6" depressed x-large @click="confirmed_inputdata = !confirmed_inputdata">
                閉じる
              </v-btn>
              <v-btn class="mt-6" x-large color="primary" v-on:click="sendMail" :hasSentMail="0"
                @click="confirmed_inputdata = !confirmed_inputdata">
                入力内容を確認して送信する
              </v-btn>
            </v-row>
          </v-container>
        </v-sheet>
      </v-bottom-sheet>
    </v-container>
  </v-card-text>
</template>

<script>
import PrivacyPolicy from "../components/PrivacyPolicy.vue";
import SendResults from "../components/SendResults.vue";

// tooltips
import Tooltips from "../components/Tooltips.vue";

import { API } from "aws-amplify";

export default {
  components: {
    PrivacyPolicy,
    SendResults,
    Tooltips,
  },
  data() {
    return {
      hasSentMail: 0,
      confirmed_inputdata: false,
      order_rule: false,
      fromDealer: false,
      repeatType: '新規仕様',

      // 問い合わせフォームの格納リスト
      form: {
        fromDealer: "",
        lastName: "",
        firstName: "",
        belong: "",
        mail: "",
        rhnumber: "",
        application: "",
        category: "",
        scientificName: "",
        sampleType: "",
        sampleNum: "",
        sampleDataAmount: "",
        sampleTypeDetail: "",
        workType: "",
        analysisType: "",
        standardPlanType: [],
        comparingPatturnNum: "",
        partnerInfo: "",
        candidateDateForSampling: "",
        repeater: "",
        notes: "",
      },

      // 個々のフォームのバリデート
      required: (value) => !!value || "必須入力項目です",
      limit_length_32: (value) =>
        value.length <= 32 || "32文字以内で入力してください",
      limit_length_64: (value) =>
        value.length <= 64 || "64文字以内で入力してください",
      address_format: (value) =>
        !!value ==
        /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/ ||
        "正しいメールアドレスを入力してください",

      // リストデータ
      applications: [
        //"WGBS",
        "WGBS（ヒト）90Gb",
        "WGBS（データ量指定）",
      ],
      category: ["真核生物", "原核生物", "その他（ウイルスなど）"],
      sampletype: ["genome DNA", "細胞", "組織"],
      repeattype: ["新規仕様", "リピート仕様（2回目以降ご注文のお客様向け）"],
      worktype: [
        "データ取得のみ",
        "データ取得＋ベーシック解析",
        "データ取得＋ベーシック解析＋スタンダード解析",
        "その他（特記事項にご記載ください）",
      ],
      analysistype: [
        "高次解析ベーシックプラン",
        "高次解析スタンダードプラン",
        "相談したい",
      ],
      standardplantype: [
        "群間比較解析：DMC(可変メチル化シトシン)・DMR(可変メチル化領域)の計算および近傍遺伝子リストの抽出 （注文単位：２群１比較）",
        "DMC・DMR近傍遺伝子のGO解析 （注文単位：２群１比較）",
        "IPAパスウェイ解析 （注文単位：２群１比較）",
        "その他（特記事項にご記入ください）",
      ],
    };
  },
  computed: {
    hasRequiredInfo() {
      // return this.$refs.order_form.validate() && this.order_rule;
      return this.order_rule;
    },
  },
  methods: {
    async sendMail() {
      const APIName = "userpageFormAPI";
      const path = "/inquiry";
      const params = {
        body: {
          inquiry: "hogehoge",
          fromDealer: this.form.fromDealer,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          belong: this.form.belong,
          mail: this.form.mail,
          rhnumber: this.form.rhnumber,
          application: this.form.application,
          category: this.form.category,
          scientificName: this.form.scientificName,
          sampleType: this.form.sampleType,
          sampleNum: this.form.sampleNum,
          sampleDataAmount: this.form.sampleDataAmount,
          sampleTypeDetail: this.form.sampleTypeDetail,
          workType: this.form.workType,
          repeatType: this.form.repeatType,
          analysisType: this.form.analysisType,
          standardPlanType: this.form.standardPlanType,
          comparingPatturnNum: this.form.comparingPatturnNum,
          partnerInfo: this.form.partnerInfo,
          candidateDateForSampling: this.form.candidateDateForSampling,
          repeater: this.form.repeater,
          notes: this.form.notes,
        },
      };

      try {
        await API.post(APIName, path, params);
        this.hasSentMail = 1;
      } catch (e) {
        this.hasSentMail = 99;
      }
    },
  },
};
</script>