<template>
  <v-container>
    <v-row>
      <v-col>
        <v-carousel cycle height="250" hide-delimiter-background>
          <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src" v-bind:href="item.link" target="_blank">
            <!-- <v-sheet
              height="100%"
              tile
            > -->
            <v-row class="white--text mx-auto" align="center" justify="center">
              <v-col align="center" justify="center">
                <p class="text-h2">
                  {{ slides[i] }}
                </p>
              </v-col>
            </v-row>
            <!-- </v-sheet> -->
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: require('@/assets/images/carousel_image/rias2.jpg'),
          link: 'https://www.rhelixa.com/rias/',
        },
        {
          src: require('@/assets/images/carousel_image/ipa.jpg'),
          link: 'https://www.rhelixa.com/ipa-service/',
        },
        {
          src: require('@/assets/images/carousel_image/pic.jpg'),
          link: 'https://www.rhelixa.com/pic/',
        }
      ],
      slides: [
        '',
        '',
        '',
      ],
    }
  }
}

</script>