<template>
  <v-app>
    <v-app-bar
      app color="grey lighten-5"
      light
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/images/common/rhelixa_logo.png"
          transition="scale-transition"
          width="100"
        />
      </div>
        <v-divider
          class="mx-4"
          vertical
        ></v-divider>
        <v-toolbar-title
         class="font-weight-medium"
         color="grey darken-1"
        >
          ［お見積用］仕様登録フォーム
        </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container>
      <Home></Home>
      <v-subheader>サービス最新情報</v-subheader>
      <v-divider></v-divider>
      <Advertisement></Advertisement>
      </v-container>
    </v-main>

    <!-- Footer -->
    <v-footer height="130" color="grey lighten-5" light padless>
      <v-row justify="center" no-gutters>
        <div>
          <v-col class="text-center" cols="12">
            株式会社Rhelixa（レリクサ）<br />
            〒104-0042 東京都中央区入舟3-7-2 KDX銀座イーストビル 5F<br />
            
            Tel : 03-6240-9330<br />
            web : 
            <a href="https://www.rhelixa.com/">https://www.rhelixa.com/</a>
            <br />
            Mail : 
            <a href="mailto:customer-service@rhelixa.com">customer-service@rhelixa.com</a>
          </v-col>
          <v-col class="text-center" cols="12">
            {{ new Date().getFullYear() }} - Copyright © Rhelixa, Inc.
          </v-col>
        </div>
      </v-row>
    </v-footer>
  </v-app>
</template>

<style>
a {
  text-decoration: none;
  color: white;
}
.text-title1 {
  padding-top: 1em;
  padding-left: 0.5em;
  font-size: 20px;
  color: #4f4f4f;
  font-weight: bold;
  white-space: pre-wrap;
}
.text-subtitle1 {
  padding-left: 0.5em;
  font-size: 16px;
  color: #4f4f4f;
  line-height: 1.5em;
  white-space: pre-wrap;
}
.text-red {
  font-size: 16px;
  color: #d44f46;
  white-space: pre-wrap;
}
.text-direction {
  font-size: 16px;
  white-space: pre-wrap;
}
.text-direction-small {
  font-size: 12px;
  white-space: pre-wrap;
}
.new-line {
  white-space: pre-wrap;
}
</style>

<script>
import Home from "./components/Home.vue";
import Advertisement from "./components/Advertise.vue";

export default {
  name: "App",

  components: {
    Home,
    Advertisement,
  },

  data: () => ({
    //
  }),
};
</script>
