<template>
    <v-container>
        <v-row>
            <v-col
                cols="12"
                md="12"
            >
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-list-item four-line>
                        <v-list-item-content>
                            <v-list-item-title> <p class="text-title1">受託サービスご依頼のお客様・代理店様へ</p></v-list-item-title>
                            <v-list-item-subtitle><p class="text-subtitle1">本ページは既にご依頼仕様が定まっているお客様向けのお見積もりフォームです。<br>以下のパネルから希望するアプリケーションを選択し、必要事項を入力の上、フォームをご送信ください。<br>弊社スタッフより速やかにお見積料金をご案内させていただきます。<br><br><b>下記にないアプリケーションのお問い合わせは<a href="https://www.rhelixa.com/entryform/inquiry-main/">こちら</a></b></p></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-avatar
                            tile
                            size="120"
                            outline
                        >
                            <v-img src="../assets/images/common/summary_4.png"></v-img>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                md="3"
            >
                <RNAseq></RNAseq>
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
                <WGS></WGS>
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
                <WES></WES>
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
                <ChIPseq></ChIPseq>
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
                <WGBS></WGBS>
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
                <RRBS></RRBS>
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
                <DNAmethylarray></DNAmethylarray>
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
                <Optional></Optional>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import RNAseq from '../orders/RNAseq.vue';
import WGS from '../orders/WGS.vue';
import WES from '../orders/WES.vue';
import ChIPseq from '../orders/ChIPseq.vue';
import WGBS from '../orders/WGBS.vue';
import RRBS from '../orders/RRBS.vue';
import DNAmethylarray from '../orders/DNAmethylarray.vue';
import Optional from '../orders/Optional.vue';

export default {
    components: {
        RNAseq,
        WGS,
        WES,
        ChIPseq,
        WGBS,
        RRBS,
        DNAmethylarray,
        Optional,
    },
}

</script>
